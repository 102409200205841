import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { getTime } from 'date-fns';
import { EnrollService } from './enroll.service';
import { NgForm } from '@angular/forms';
import { timer, Subscription } from 'rxjs'; // (for rxjs < 6) use 'rxjs/observable/timer'
import { take, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss']
})
export class EnrollComponent implements OnInit {

  authenticated: boolean;
  qr_value: string;

  school_uid: string;
  uid: string;
  auth: any;
  temp_uid: string;
  count_down;
  timee: number;
  get_temp_qr_code_sub: Subscription;
  timer_number: number;
  countDownInterv: any;
  img1: any;

  school_profile: object;
  grade_levels: object;

  studentstatus: string;
  reportcard: Boolean;
  nsocertificate: Boolean;
  goodmoral: Boolean;
  picture2x2: Boolean;
  transfercredentials: Boolean;
  medicalrecord: Boolean;
  childname: string;
  childname2: string;
  childmiddlename: string;
  childlastname: string;
  childsuffix: string;
  birthyear: string;
  birthmonth: string;
  birthday: string;
  medicalcondition: string;
  childgradelevel: string;
  sex: string;
  birthplace: string;
  street: string;
  barangay: string;
  municipality: string;
  city: string;
  province: string;
  addresstel: string;
  provincetel: string;
  pastschoolyear: string;
  pastgradelevel: string;
  pastschoolname: string;
  pasthonorsearned: string;
  fathername: string;
  fathermiddlename: string;
  fatherlastname: string;
  fatheroccupation: string;
  fatheremail: string;
  fatherbusinessaddress: string;
  fatherbusinesstel: string;
  fatherchurch: string;
  mothername: string;
  mothermaidenname: string;
  motherlastname: string;
  motheroccupation: string;
  motheremail: string;
  motherbusinessaddress: string;
  motherbusinesstel: string;
  motherchurch: string;
  guardianname: string;
  guardianlastname: string;
  guardiannumber: string;
  introduced: string;
  
  agree: boolean;
  reuseForm: boolean;

  missing_info: Array<any>;

  constructor(private enrollServ: EnrollService, private spinner: NgxSpinnerService) {
    this.timer_number = 20;
    this.timee = this.timer_number;
    this.img1 = new Image();
  }

  ngOnInit() {
    this.loginToGetQRCode();
    // const tempbot = {
    //   email: "cunivs.enroller@classemo.com",
    //   password: "cunivs.enroller.2814192",
    //   role: "enroller",
    //   school: "-KDwhnIAfj0EFUhB3oLz"
      // email: "cbs.enroller@c;,,P_y3"
    // };
    // this.loginBot(tempbot);
  }

  countDown() {
    this.count_down = timer(0, 1000).pipe(
      take(this.timee),
      map(()=> --this.timee)
    );
  }

  loginToGetQRCode() {
    const email = 'enroller.bot@classemo.com';
    const password = '!123456';

    this.enrollServ.afAuth.auth.signInWithEmailAndPassword(email, password).then((auth) => {
      console.log('auth', auth);

      this.countDown();

      this.setQRCode(this.generateUUID());

      this.countDownInterv = setInterval(() => {
        this.timee = this.timer_number;
        this.countDown();
        this.removeTempQRCode().then(() => {
          this.setQRCode(this.generateUUID());
        });
      }, 19000);
    });
  }

  generateUUID() {
    const uuid = this.enrollServ.generateUUID();
    return this.qr_value = uuid;
  }

  setQRCode(qr_code) {
    const payload = {
      qr_code
    };

    return this.enrollServ.setTempQRCode(payload).then((temp) => {
      this.temp_uid = temp.key;
      console.log('temp_uid', this.temp_uid);
      this.getTempQRCode();
    });
  }
  
  removeTempQRCode() {
    console.log('temp_uid to remove', this.temp_uid);
    this.get_temp_qr_code_sub.unsubscribe();

    return this.enrollServ.removeTempQRCode(this.temp_uid).then(() => {
      console.log('removed temp qr code');
    });
  }

  getTempQRCode() {
    this.get_temp_qr_code_sub = this.enrollServ.getTempQRCode(this.temp_uid).subscribe((temp_bot) => {
      if (temp_bot && temp_bot['email'] && temp_bot['role'] === 'enroller') {
        temp_bot['temp_uid'] = this.temp_uid;
        
        this.loginBot(temp_bot);
      }
      console.log('temp_bot received', temp_bot);
    });
  }

  loginBot(payload) {
    this.enrollServ.afAuth.auth.signOut().then(() => {
      this.enrollServ.afAuth.auth.signInWithEmailAndPassword(payload.email, payload.password).then((auth) => {
        this.auth = auth;
        this.school_uid = payload.school;

        this.getSchoolProfile();
        this.getLevels();
        
        clearInterval(this.countDownInterv);

        this.removeTempQRCode().then(() => {
          console.log('logged in and cleared interval');
        });
      });
    });
  }

  getSchoolProfile() {
    const payload = {
      school_uid: this.school_uid
    };
    this.enrollServ.getSchoolProfile(payload).subscribe((school_profile) => {
      this.school_profile = school_profile;
      console.log('this.school_profile', this.school_profile);
    });
  }

  getLevels() {
    const payload = {
      school_uid: this.school_uid
    };
    this.enrollServ.getLevels(payload).snapshotChanges().subscribe((results) => {
      const array = [];

      _.forEach(results, (val) => {
        let data = val.payload.toJSON();
        data['key'] = val.key;
        array.push(data);        
      });
      
      this.grade_levels = array;
      this.childgradelevel = '';
      console.log('this.grade_levels', this.grade_levels);
    });
  }

  agreeCheckForm($event) {
    if ($event) {
      this.missing_info = [];

      !this.studentstatus &&this. missing_info.push('Student Status');
      !this.childname &&this. missing_info.push('Student\'s First Name');
      !this.childmiddlename && this.missing_info.push('Student\'s Middle Name');
      !this.childlastname &&this. missing_info.push('Student\'s Last Name');
      !this.birthyear &&this. missing_info.push('Birth Year');
      !this.birthmonth &&this. missing_info.push('Birth Month');
      !this.birthday &&this. missing_info.push('Birth Day');
      !this.childgradelevel && this.missing_info.push('Student Grade Level');
      !this.sex && this.missing_info.push('Student Sex');
      !this.birthplace &&this. missing_info.push('Place of Birth');
      !this.barangay &&this. missing_info.push('Barangay');
      !this.province &&this. missing_info.push('Provincial Address');
      !this.addresstel &&this. missing_info.push('Home Telephone');
      !this.provincetel &&this. missing_info.push('Provincial Telephone');
      !this.guardianname &&this. missing_info.push('Guardian\'s Name');
      !this.guardianlastname && this.missing_info.push('Guardian\'s Last Name');
      !this.guardiannumber && this.missing_info.push('Guardian\'s Number');

      if (_.size(this.missing_info) > 0) {
        alert(`You have missing information: ${this.missing_info}`);
        this.agree = false;
      }
    }
  }

  submitForm(form: NgForm) {
    const enrollmentForm = form.value;
    console.log('enrollmentForm', enrollmentForm);

    const school_uid = this.school_uid;

    const student_info = {
      active: true,
      name: {
        firstname: enrollmentForm.childname,
        secondname: enrollmentForm.childname2 || null,
        middlename: enrollmentForm.childmiddlename,
        lastname: enrollmentForm.childlastname,
        suffix: enrollmentForm.childsuffix || null
      },
      enrollment: {
        submitted_requirements: {
          report_card: enrollmentForm.reportcard || null,
          nso_certificate: enrollmentForm.nsocertificate || null,
          good_moral: enrollmentForm.goodmoral || null,
          picture2x2: enrollmentForm.picture2x2 || null,
          transfer_credentials: enrollmentForm.transfercredentials || null,
          medical_record: enrollmentForm.medicalrecord || null
        },
        previous_school: {
          school_year: enrollmentForm.pastschoolyear || null,
          grade_level: enrollmentForm.pastgradelevel || null,
          school_name: enrollmentForm.pastschoolname || null,
          past_honors_earned: enrollmentForm.pasthonorsearned || null
        }
      },
      birthdate: getTime(new Date(+enrollmentForm.birthyear, +enrollmentForm.birthmonth, +enrollmentForm.birthday)),
      medical_condition: enrollmentForm.medicalcondition || null,
      level: enrollmentForm.childgradelevel,
      gender: +enrollmentForm.sex,
      birth_place: enrollmentForm.birthplace || null,
      address: {
        street: enrollmentForm.street || null,
        barangay: enrollmentForm.barangay || null,
        municipality: enrollmentForm.municipality || null,
        city: enrollmentForm.city || null
      },
      contact: {
        phone: enrollmentForm.addresstel || null,
        emergency_contact: `${enrollmentForm.guardianname} ${enrollmentForm.guardianlastname}`,
        emergency_contact_number: enrollmentForm.guardiannumber
      },
      introduced: enrollmentForm.introduced || null
    };

    const father_info = {
      active: true,
      role: 'parent',
      gender: 10,
      name: {
        firstname: enrollmentForm.fathername || null,
        middlename: enrollmentForm.fathermiddlename || null,
        lastname: enrollmentForm.fatherlastname || null
      },
      occupation: enrollmentForm.fatheroccupation || null,
      contact: {
        email: enrollmentForm.fatheremail || null,
        phone: enrollmentForm.fatherphone || null
      },
      business: {
        address: enrollmentForm.fatherbusinessaddress || null,
        phone: enrollmentForm.fatherbusinesstel || null
      },
      religion: {
        church_affiliation: enrollmentForm.fatherchurch || null
      }
    };

    const mother_info = {
      active: true,
      role: 'parent',
      gender: 20,
      name: {
        firstname: enrollmentForm.mothername || null,
        middlename: enrollmentForm.mothermaidenname || null,
        lastname: enrollmentForm.motherlastname || null
      },
      occupation: enrollmentForm.motheroccupation || null,
      contact: {
        email: enrollmentForm.motheremail || null,
        phone: enrollmentForm.motherphone || null
      },
      business: {
        address: enrollmentForm.motherbusinessaddress || null,
        phone: enrollmentForm.motherbusinesstel || null
      },
      religion: {
        church_affiliation: enrollmentForm.motherchurch || null
      }
    };

    const student_payload = {
      school_uid,
      data: student_info
    };

    console.log('student_payload', student_payload);
    // return;

    this.spinner.show();
    // save student profile
    this.enrollServ.createStudent(student_payload).then((result) => {
      const student_uid = result.key;
      // const student_uid = '21212';

      this.createParentProfile(father_info, student_info, student_uid).then(() => {
        this.createParentProfile(mother_info, student_info, student_uid).then(() => {
          alert('Form submitted and sent to the printer. Please call the attention of a school staff for the next step of the enrollment process. Thank you.');

          this.downloadPDF(student_info['name']);
          this.spinner.hide();
          if (enrollmentForm.reuseForm) {
            // new form
            this.resetReuseForm();
          } else {
            // reuse
            form.resetForm();
          }
        });
      });
    });
  }

  resetReuseForm() {
    this.studentstatus = null;
    this.reportcard = null;
    this.nsocertificate = null;
    this.goodmoral = null;
    this.picture2x2 = null;
    this.transfercredentials = null;
    this.medicalrecord = null;
    this.childname = null;
    this.childname2 = null;
    this.childmiddlename = null;
    this.childsuffix = null;
    this.birthyear = null;
    this.birthmonth = null;
    this.birthday = null;
    this.medicalcondition = null;
    this.childgradelevel = null;
    this.addresstel = null;
    this.provincetel = null;
    this.pastschoolyear = null;
    this.pastgradelevel = null;
    this.pastschoolname = null;
    this.pasthonorsearned = null;
  }

  createParentProfile(parent_info, student_info, student_uid) {
    return new Promise((resolve, reject) => {
      let parent_payload = {
        school_uid: this.school_uid
      };

      if (parent_info.name.firstname) {
        parent_info['levels'] = {};
        parent_info['levels'][student_info.level] = true;
        parent_info['children'] = {};
        parent_info['children'][student_uid] = true;
      }

      if (parent_info.contact && parent_info.contact.email) {
        // register parent and save profile
        parent_info['password'] = this.enrollServ.passwordGenerator(parent_info.contact.email);

        parent_payload['data'] = parent_info;
        console.log('parent_payload', parent_payload);
        // return;

        this.enrollServ.createParent(parent_payload).then((parent_uid) => {
          // save parent to student
          let parents_data = {};
          parents_data[`parents/${parent_uid}`] = true;

          const student_update_payload = {
            school_uid: this.school_uid,
            student_uid,
            data: parents_data
          };
          this.enrollServ.updateStudent(student_update_payload).then(() => {
            resolve();
          });
        })
        .catch((err) => {
          alert(err.message);
          reject();
        });
      } else {
        // save pending parent profile
        parent_payload['data'] = parent_info;
        
        this.enrollServ.createPendingParent(parent_payload).then(() => {
          resolve();
        });
      }
    });
  }

  downloadPDF(student_name){
    this.tempResetForm(' ');
    document.getElementById('submit_section').style.display = 'none';
    const quotes = document.getElementById('form_results');

    this.img1.onload = function() {
      html2canvas(quotes).then((canvas) => {
        var width = canvas.width;
        var height = canvas.height;
        var millimeters = {};
        millimeters['width'] = Math.floor(width * 0.264583);
        millimeters['height'] = Math.floor(height * 0.264583);

        var imgData = canvas.toDataURL(
            'image/jpeg', 0.8);
        var doc = new jsPDF("p", "mm", "legal", 1);
        doc.deletePage(1);
        doc.addPage([millimeters['width'], millimeters['height']]);
        doc.addImage(imgData, 'PNG', 0, 0);
        doc.save(`${student_name.lastname}, ${student_name.firstname}-Enrollment Form.pdf`);
        this.tempResetForm('');
      }).then(() => {
        
      });
    };
    this.img1.crossOrigin = '';  // for demo as we are at different origin than image
    this.img1.src = this.school_profile['school_logo_256_thumb'];
  }

  tempResetForm(val) {
    this.reportcard = this.reportcard || val;
    this.nsocertificate = this.nsocertificate || val;
    this.goodmoral = this.goodmoral || val;
    this.picture2x2 = this.picture2x2 || val;
    this.transfercredentials = this.transfercredentials || val;
    this.medicalrecord = this.medicalrecord || val;
    this.childname = this.childname || val;
    this.childname2 = this.childname2 || val;
    this.childmiddlename = this.childmiddlename || val;
    this.childlastname = this.childlastname || val;
    this.childsuffix = this.childsuffix || val;
    this.birthyear = this.birthyear || val;
    this.birthmonth = this.birthmonth || val;
    this.birthday = this.birthday || val;
    this.medicalcondition = this.medicalcondition || val;
    this.childgradelevel = this.childgradelevel || val;
    this.sex = this.sex || val;
    this.birthplace = this.birthplace || val;
    this.street = this.street || val;
    this.barangay = this.barangay || val;
    this.municipality = this.municipality || val;
    this.city = this.city || val;
    this.province = this.province || val;
    this.addresstel = this.addresstel || val;
    this.provincetel = this.provincetel || val;
    this.pastschoolyear = this.pastschoolyear || val;
    this.pastgradelevel = this.pastgradelevel || val;
    this.pastschoolname = this.pastschoolname || val;
    this.pasthonorsearned = this.pasthonorsearned || val;
    this.fathername = this.fathername || val;
    this.fathermiddlename = this.fathermiddlename || val;
    this.fatherlastname = this.fatherlastname || val;
    this.fatheroccupation = this.fatheroccupation || val;
    this.fatheremail = this.fatheremail || val;
    this.fatherbusinessaddress = this.fatherbusinessaddress || val;
    this.fatherbusinesstel = this.fatherbusinesstel || val;
    this.fatherchurch = this.fatherchurch || val;
    this.mothername = this.mothername || val;
    this.mothermaidenname = this.mothermaidenname || val;
    this.motherlastname = this.motherlastname || val;
    this.motheroccupation = this.motheroccupation || val;
    this.motheremail = this.motheremail || val;
    this.motherbusinessaddress = this.motherbusinessaddress || val;
    this.motherbusinesstel = this.motherbusinesstel || val;
    this.motherchurch = this.motherchurch || val;
    this.guardianname = this.guardianname || val;
    this.guardianlastname = this.guardianlastname || val;
    this.guardiannumber = this.guardiannumber || val;
    this.introduced = this.introduced || val;
  }
}
