import { Injectable, Inject } from '@angular/core';
// import { FirebaseApp } from 'angularfire2';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { environment } from './../../../environments/environment';
import { UUID } from 'angular2-uuid';

@Injectable()
export class EnrollService {
    public firebase_ref = firebase.database().ref();
    public parentFbaseConnect = firebase.initializeApp(environment.firebase, 'Enroll');

    constructor(
    public afAuth: AngularFireAuth,
    public db: AngularFireDatabase,
    // @Inject(FirebaseApp) public fbApp: any
    ) {
    }

    getAllUsers(school) {
        return this.db.list('schools/' + school + '/user_profiles_list');
    }

    generateUUID() {
        let uuid = UUID.UUID();
        return uuid;
    }

    getSchoolProfile(payload) {
        return this.db.object(`schools/${payload.school_uid}/profile`).valueChanges();
    }

    getLevels(payload) {
        return this.db.list(`schools/${payload.school_uid}/levels`, ref => ref.orderByChild('active').equalTo(true));
    }

    setTempQRCode(payload) {
        return this.db.list('temp_enrollment_codes').push(payload);
    }

    getTempQRCode(temp_uid) {
        return this.db.object(`temp_enrollment_codes/${temp_uid}`).valueChanges();
    }

    removeTempQRCode(temp_uid) {
        return this.db.object(`temp_enrollment_codes/${temp_uid}`).set(null);
    }
    
    passwordGenerator(email) {
        const split_email = email.split('@');
        const email_prefix = split_email[0];
        const random = Math.floor(Math.random() * 9000) + 100;
        const password = email_prefix.toString() + random;

        return password;
    }

    createStudent(payload) {
        payload['data']['date_created'] = firebase.database.ServerValue.TIMESTAMP;
        payload['data']['enrollment']['date'] = firebase.database.ServerValue.TIMESTAMP;
            
        return this.db.list(`schools/${payload.school_uid}/students`).push(payload.data);
    }

    createParent(payload) {
        payload['data']['date_created'] = firebase.database.ServerValue.TIMESTAMP;

        const school_uid = payload.school_uid;
        const data = payload.data;
        const email = data.contact.email;
        const password = data.password;

        return this.parentFbaseConnect.auth().createUserWithEmailAndPassword(email, password).then(new_user => {
            console.log('new USER ID: ', new_user);
            const new_user_uid = new_user.user.uid;

            return this.parentFbaseConnect.auth().signOut().then(() => {
                const user_ref = `schools/${school_uid}/user_profiles_list/${new_user_uid}`;

                return this.db.object(user_ref).update(data).then(() => {
                    const users_ref = `users/${new_user_uid}/school`;

                    return this.db.object(users_ref).set(school_uid).then(() => {
                        return new_user_uid;
                    });
                });
            });
        });
    }

    createPendingParent(payload) {
        payload['data']['date_pending'] = firebase.database.ServerValue.TIMESTAMP;

        const school_uid = payload.school_uid;
        const users_pending_ref = `schools/${school_uid}/users_pending/`;

        return this.db.list(users_pending_ref).push(payload.data);
    }

    updateStudent(payload) {
        const student_ref = `schools/${payload.school_uid}/students/${payload.student_uid}`;
        return this.db.object(student_ref).update(payload.data);
    }

}
